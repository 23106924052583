@use "../../../styles/colors.scss";

.hint {
	display: flex;
	flex-wrap: wrap;
	word-wrap: break-word;
	align-self: center;

	svg {
		margin-right: 8px;
	}

	a {
		color: colors.$darkblue;

		&:visited {
			color: colors.$darkblue;
		}
	}
}

.textarea_container {
	position: relative;
	height: 100%;
	display: flex;
	width: 100%;
	flex: 1;
	flex-direction: column;

	.overlay {
		padding: 0 16px;

		> * {
			text-align: center;
		}

		cursor: pointer;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		z-index: 1;
	}
}
