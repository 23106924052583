.delete_dialog_container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-left: auto;
	margin-right: auto;

	> :first-child {
		margin-right: 12px;
	}

	cursor: pointer;
}
