@use "../../styles/colors.scss";

.learning_container {
	display: flex;
	flex-direction: column;
	width: 100%;
	max-height: 100%;
	height: 100%;
}

.bottom_container {
	height: 120px;
	min-height: 120px;
	max-height: 120px;
	justify-content: flex-end;
}

.answer_container {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	width: 100%;

	p {
		margin-top: 6px;
		text-align: center;
	}
}

.align_left {
	align-self: flex-start;
}

.floating_button_container {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 80px;
	height: 80px;
	border-radius: 50%;
	background-color: colors.$lightblue;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

	> * {
		height: 100%;
		width: 100%;
		cursor: pointer;
	}
}

.card_text {
	position: absolute;
	font-family: Berlin Sans FB;
	font-size: 1.4rem;
	font-style: normal;
	font-weight: normal;
	line-height: 24px;
	letter-spacing: 0em;
	word-break: break-word;

	overflow-y: auto;
	overflow-x: hidden;
	text-align: left;
	width: 100%;
	height: 100%;
	min-height: 100%;
	max-height: 100%;

	padding: 0 16px;

	overflow-wrap: break-word;
	white-space: pre-wrap;
}
