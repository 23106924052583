@use "../../styles/colors.scss";

.container {
	width: 100%;
	height: 14px;
	min-height: 14px;
	max-height: 14px;
	background-color: colors.$lightblue;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 1px;
	border-radius: 20px;

	.bar {
		height: 100%;
		background-color: colors.$darkblue;
	}

	> *:not(:last-child) {
		margin-right: 1px;
	}

	> :first-child {
		border-bottom-left-radius: 20px;
		border-top-left-radius: 20px;
	}

	.last_bar {
		border-bottom-right-radius: 20px;
		border-top-right-radius: 20px;
	}
}
