@use "../../../styles/colors.scss";

.context_menu_item {
	height: 40px;
	width: 40px;
	flex: 1;
	min-height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 6px;
	align-self: stretch;

	cursor: pointer;
	&:hover {
		background-color: colors.$hovergray;
	}
}
