@use "../../../styles/colors.scss";

.toggle_button_container {
	display: flex;
	flex-direction: row;

	margin-left: auto;
	margin-right: auto;

	border-radius: 4px;
	box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25),
		-4px -4px 8px rgba(207, 207, 207, 0.25);

	padding: 4px 3px;
}

.button {
	cursor: pointer;
	outline: inherit;
	border: none;

	color: colors.$darkblue;
	width: 90px;
	border-radius: 4px;

	padding: 8px 12px; // Adjust padding as necessary
	background-color: white; // Use transparent background for buttons
	transition: color 0.3s ease; // Transition for text color or other effects
}

.active {
	background-color: colors.$darkblue;
	color: white;
}
