$screen-s: 500px;
$screen-m: 982px;

@mixin s {
	@media (min-width: #{$screen-s}) {
		@content;
	}
}

@mixin m {
	@media (min-width: #{$screen-m}) {
		@content;
	}
}

@mixin l {
	@media (min-width: #{$screen-l}) {
		@content;
	}
}
