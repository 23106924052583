@use "../../styles/breakpoints.scss";

.icon {
	width: 30px;
	height: 30px;

	@include breakpoints.s {
		width: 50px;
		height: 50px;
	}
}
