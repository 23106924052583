@use "../../../styles/vars.scss";
@use "../../../styles/colors.scss";

.menu {
	background-color: white;
	border: 0.2px solid colors.$lightgray;
	height: auto;
	display: flex;
	flex-direction: column;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
