.error_page_container {
	flex-grow: 1;
	display: flex;
	flex-direction: column;

	background: radial-gradient(
		50% 50% at 50% 50%,
		#cfd162 0%,
		rgba(207, 209, 98, 0) 100%
	);
}

.content_container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex-grow: 1;
}

.center {
	text-align: center;
}
