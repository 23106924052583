@use "../../../styles/colors.scss";
@use "../../../styles/breakpoints.scss";

.item {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 6px 16px;
	border-bottom: 0.2px solid colors.$lightgray;

	@include breakpoints.s {
		border-bottom: none;
	}

	svg {
		margin-left: 8px;
		cursor: pointer;
		box-shadow: none;
	}
}
