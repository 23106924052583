.text {
	margin: 0;
	font-size: 1.1rem;
	font-style: normal;
	font-weight: 400;
	letter-spacing: 0em;
	text-align: left;

	a {
		text-decoration: underline;
	}
}
