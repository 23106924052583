.img_container {
	display: flex;
	flex: 1 1 auto;
	position: relative;
}

.img {
	top: 0;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	position: absolute;
	display: block;
	max-width: 100%;
	max-height: 100%;
	width: 100%;
	height: 100%;
	min-height: 0;
}
