.center {
	text-align: center;
}

.img {
	max-width: 200px;
}

.container {
	display: flex;
	flex: 1;
	flex-direction: column;
}
