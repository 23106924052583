.swipe_container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	svg {
		cursor: pointer;
	}

	> :last-child {
		transform: rotate(180deg);
	}
}
