@use "../../styles/vars.scss";

.input_container_overlay {
	position: absolute;
	background: rgba(255, 255, 255, 0.7);
	width: 100%;
	height: calc(100% - 133px);
	bottom: 0;
	left: 0;
	margin: 0 !important;
	z-index: 5;
}

.color_header {
	height: 133px;
	display: flex;
	margin-left: 0 - (vars.$page-gap);
	margin-right: 0 - (vars.$page-gap);
	position: relative;

	span {
		position: relative;
		align-self: flex-end;
		margin-left: auto;
		margin-right: 39px;
		margin-bottom: 15px;
	}

	.color_popup {
		position: absolute;
		right: 0;
		margin-top: 3px;
	}
}
