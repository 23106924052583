@use "../../styles/vars.scss";
@use "../../styles/breakpoints.scss";
@use "../../styles/colors.scss";
@use "../../styles/styles.scss";

.container {
	@include styles.custom_scrollbar;

	max-width: vars.$content-area-width;
	padding: 8px vars.$page-gap;

	flex: 1;
	flex-direction: column;
	align-self: center;
	display: flex;
	align-items: center;
	width: 100%;
	height: 100%;

	@include breakpoints.s {
		display: grid;
		justify-content: center;
		align-items: flex-start;
		align-content: flex-start;
	}
}
