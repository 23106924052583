.context_menu_container {
	position: absolute;
	background-color: white;

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	border-radius: 8px;
	overflow: hidden;
	border-collapse: collapse;
	border: 1px solid #ccc;
}
