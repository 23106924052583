@use "../../styles/breakpoints.scss";
@use "../../styles/vars.scss";
@use "../../styles/colors.scss";

.container {
	background-color: colors.$lightblue;
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow: auto;
	position: relative;
	min-width: 100vw;
	max-width: 100vw;
	width: 100vw;
}

.content {
	display: flex;
	flex: 1;
	flex-direction: column;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	padding-left: vars.$page-gap;
	padding-right: vars.$page-gap;
}

.extended_full {
	max-width: vars.$content-area-width * 1.2 !important;
}

.full {
	max-width: vars.$content-area-width !important;
}

.normal {
	max-width: vars.$content-width;
}

.reader {
	max-width: vars.$reader-width;
}

.content_area {
	padding-bottom: 8px;
	background-color: white;
	flex: 1;
	display: flex;
	flex-direction: column;
	margin-left: auto;
	margin-right: auto;
	max-width: vars.$content-area-width;
	width: 100%;
	position: relative;
	overflow: inherit;
}

.with_navbar {
	margin-top: calc(vars.$navbar-height);
	padding-top: 8px;
}

.without_navbar {
	padding-top: calc(vars.$navbar-height + 8px);
}
