@use "../../styles/colors.scss";
@use "../../styles/breakpoints.scss";

.h1 {
	font-style: normal;
	font-weight: 500;
	line-height: 81px;
	letter-spacing: 0em;
	margin: 0;
	font-size: 3rem;

	color: colors.$darkblue;
}

.h2 {
	@include breakpoints.s {
		font-size: 3rem;
	}
	font-style: normal;
	font-weight: 700;
	line-height: 48px;
	letter-spacing: 0em;
	margin: 0;
	font-size: 2rem;
	color: colors.$darkblue;
}

.h3 {
	font-style: normal;
	font-weight: 700;
	line-height: 24px;
	letter-spacing: 0em;
	margin: 0;
	font-size: 1.2rem;
	color: colors.$darkblue;
}
