@use "../../styles/vars.scss";
@use "../../styles/breakpoints.scss";

.container {
	position: relative;
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
	align-items: center;
}
