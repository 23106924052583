.border_radius_top {
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
}

.card_input_container {
	width: 100%;

	height: 100%;
	min-height: 0;
	max-height: 100%;

	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;

	position: relative;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

	display: grid;
	grid-template-areas:
		"question"
		"answer";

	grid-template-rows: 50% 50%;
	grid-template-columns: 100%;
	align-items: center;

	> * {
		font-family: "Berlin Sans FB" !important;
	}

	.line {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		width: 90%;
		height: 1px;
		background-color: white;
	}
}
