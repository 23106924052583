.content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: auto;

	h2 {
		text-align: center;
	}
	p {
		text-align: center;
	}
}

.email {
	text-decoration: underline;
}
