.cards {
	svg {
		width: 24px;
		height: 24px;
		margin-right: 8px;
	}
	display: flex;
	flex-direction: row;
	align-items: center;
}
