@use "../../../styles/colors.scss";

.unstyled_button {
	background: none;
	color: inherit;
	border: none;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.divider {
	display: flex;
	flex: 1;
}

.divider > :first-child {
	min-width: 25%;
}

.divider > :last-child {
	min-width: 25%;
}
