@use "./breakpoints.scss";

$navbar-height: 55px;
$page-gap: 16px;
$content-width: breakpoints.$screen-s;
$reader-width: 648px;
$content-area-width: breakpoints.$screen-m;

// z-indices
$overlay: 1000;
$popup: $overlay + 100;
