@use "../../styles/colors.scss";
@use "../../styles/vars.scss";
@use "../../styles/breakpoints.scss";

.deck_detail_header {
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;

	> svg {
		cursor: pointer;
	}

	> a,
	> a:focus {
		cursor: pointer;
		display: inline-flex;
	}
}

.center {
	display: flex;
	width: fit-content;
	margin-left: auto;
	margin-right: auto;
}

.info_icon {
	margin-left: auto;
}

.floating_container {
	position: fixed;
	bottom: 20px;
	right: vars.$page-gap;
	width: 158px;

	@include breakpoints.s {
		width: 343px;
		right: 0;
		left: 0;
		margin-left: auto;
		margin-right: auto;
	}

	svg {
		margin-right: 12px;
	}
}

.description_popup {
	position: absolute;
	width: 100%;
	max-width: 500px;
	top: 35px;
	background: colors.$lightblue;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 20px;
	z-index: vars.$popup;
	padding: 17px;

	@include breakpoints.s {
		top: 40px;
		right: 0;
	}

	p {
		word-break: break-word;
	}
}

.create_card_popup_container {
	position: absolute;
	width: 171px;

	right: 0;
	top: 50px;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;

	background-color: white;
	border: 0.5px solid #8c8c8c;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 20px;

	& > :first-child {
		border-top-right-radius: 20px;
		border-top-left-radius: 20px;
		padding-top: 12px;
	}

	& > :last-child {
		border-bottom-right-radius: 20px;
		border-bottom-left-radius: 20px;
		padding-bottom: 16px;
	}

	z-index: vars.$popup;
}

.popup_item {
	position: relative;
	svg {
		margin-right: 8px;
	}
	padding: {
		top: 8px;
		bottom: 8px;
		left: 16px;
		right: 16px;
	}

	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;

	&:hover {
		background-color: colors.$hovergray;
	}
}

.unauthorized {
	svg {
		path {
			fill: colors.$lightgray;
		}
	}

	p {
		color: colors.$lightgray !important;
	}

	.disabled {
		pointer-events: none;
	}

	&:hover {
		background-color: white;
		cursor: default;
	}
}

.beta {
	position: absolute;
	right: 35px;
	bottom: 30px;
	font-size: 12px;
}
