@use "../../styles/colors.scss";

.simple_button {
	width: 100%;
	height: 42px;
	min-height: 42px;
	max-height: 42px;
	max-width: 343px;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	border: none;
	background: none;
	color: colors.$darkblue;
	font-size: 18px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
}
