@use "../../styles/vars.scss";
@use "../../styles/breakpoints.scss";

.container {
	display: flex;
	flex-direction: column;
	margin-left: auto;
	margin-right: auto;
	flex-grow: 1;
	width: 100%;
	padding: 0 vars.$page-gap;
	@include breakpoints.s {
		max-width: vars.$content-width;
		padding: 0;
	}
}
