@use "../../../styles/colors.scss";

.readability_content {
	position: relative;
	font-family: sans-serif;
	line-height: 1.6em;

	picture {
		max-width: 100%;
		height: auto;
	}
	figcaption {
		color: colors.$darkblue;
	}
	code {
		white-space: pre-wrap;
	}
	a {
		text-decoration: underline;
	}
}

.img {
	max-width: 100%;
	height: auto;
	cursor: zoom-in;
}

.h1 {
	font-size: 1.6em;
	line-height: 1.5em;
	font-weight: bold;
	margin: 12px;
}

.h2 {
	font-size: 1.3em;
	font-weight: bold;
	margin: 10px;
}

.h3 {
	font-size: 1.2em;
	font-weight: bold;
	margin: 8px;
}

.highlight {
	background-color: colors.$lightgray;
}

::highlight(card-selected-highlight) {
	background-color: rgba(0, 0, 0, 0.5);
}

::highlight(text-highlight) {
	background-color: rgba(190, 189, 255, 0.5);
}

.inverted_svg {
	background-color: colors.$darkblue !important;

	svg {
		color: white;
		path {
			//fill: white;
			fill: currentColor;
		}
	}
}
