@use "../../styles/colors.scss";

.container {
	margin: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.loader {
	border-radius: 50%;
	animation: spin 1.7s linear infinite;
}

.small {
	height: 23px;
	width: 23px;
	border: 3px solid white;
	border-top: 3px solid colors.$darkblue;
}

.large {
	height: 60px;
	width: 60px;
	border: 8px solid white;
	border-top: 8px solid colors.$darkblue;
}

@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
