@use "../../styles/colors.scss";
@use "../../styles/animations.scss";
@use "../../styles/breakpoints.scss";

.content_container {
	cursor: pointer;
	display: flex;
	flex-direction: row-reverse;
	justify-content: flex-start;

	border-radius: 20px;
	border: 1px solid colors.$lightgray;
	width: 100%;
	height: 132px;
	min-height: 132px;

	overflow: hidden;

	@include animations.scale_hover;

	@include breakpoints.s {
		width: 224px;
		height: 262px;
		flex-direction: column;
	}

	.content_name {
		margin: 16px;
		display: flex;
		flex: 1;
		position: relative;

		display: -webkit-box;
		line-clamp: 4;
		-webkit-line-clamp: 4;
		-webkit-box-orient: vertical;
		overflow: hidden;

		@include breakpoints.s {
			-webkit-line-clamp: 5;
			height: 60%;
			width: auto;
		}
	}

	.image_container {
		height: 100%;
		min-width: 35%;
		width: 35%;
		overflow: hidden;

		@include breakpoints.s {
			width: 100%;
			height: 40%;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}

.tag {
	position: absolute;
	bottom: 0px;
	right: 0px;
	fill: colors.$pastelRed;
}
