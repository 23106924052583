.icon_button_container {
	cursor: pointer;

	border: none;
	background-color: white;

	height: 32px;
	width: 32px;
	padding: 5px;
	display: flex;
	justify-content: center;
	align-items: center;

	box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25),
		-4px -4px 8px rgba(207, 207, 207, 0.25);
	border-radius: 4px;

	transform: scale(1);
	transition: 0.2s ease-out;
	-webkit-transform: scale(1);
	-webkit-transition: 0.2s ease-out;

	svg {
		height: 100%;
		width: 100%;
	}

	&:hover {
		transform: scale(1.03);
		-webkit-transform: scale(1.03);
		box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.25),
			-2px -2px 4px rgba(207, 207, 207, 0.25);
	}
}

.disabled {
	cursor: not-allowed;
	opacity: 0.5;
	// no transform:;
	&:hover {
		transform: none;
		-webkit-transform: none;
		box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25),
			-4px -4px 8px rgba(207, 207, 207, 0.25);
	}
}
