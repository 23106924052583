.icon_container {
	display: flex;
	justify-content: flex-end;
	align-items: center;

	:first-child {
		margin-right: auto;
	}

	& > *:not(:last-child):not(:first-child) {
		margin-right: 8px;
	}
}
