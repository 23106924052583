.math_block {
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding: 16px 4px;
	width: 100%;
	cursor: pointer;

	&:hover {
		background-color: rgba(0, 0, 0, 0.1);
		border-radius: 8px;
	}
}
