@use "../../../../styles/colors.scss";

::highlight(text-highlight) {
	background-color: rgba(190, 189, 255, 0.5);
}

.text_container {
	resize: horizontal;
	padding: 8px;
	margin: {
		top: 8px;
		bottom: 8px;
		left: 16px;
		right: 16px;
	}
	overflow: auto;
	border-radius: 20px;

	border: 1px solid colors.$lightgray;
	background: colors.$lightblue;
}
