@use "../../styles/colors.scss";

.secondary_button {
	width: 100%;
	height: 42px;

	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 20px;

	color: colors.$darkblue;
	font-size: 18px;
	border: none;
	cursor: pointer;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}
