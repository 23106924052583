@use "../../../styles/animations.scss";
@use "../../../styles/breakpoints.scss";
@use "../../../styles/styles.scss";

.card_container {
	@include breakpoints.s {
		width: 224px;
		min-width: 224px;
		max-width: 224px;
		height: 260px;
		align-items: flex-start;
	}
	overflow: hidden;
	height: 120px;
	min-height: 120px;
	width: 100%;
	border-radius: 20px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	position: relative;

	display: grid;
	grid-template-areas:
		"question"
		"answer";

	grid-template-rows: 46% 46%;
	grid-template-columns: 100%;
	grid-gap: 6%;
	align-items: center;

	padding: 16px;

	> :first-child {
		grid-area: question;
	}

	> :nth-child(2) {
		grid-area: answer;
	}

	@include animations.scale_hover;

	.line {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		width: 100%;
		height: 1px;
		background-color: white;
	}

	.card_text {
		font-family: "Berlin Sans FB";
		white-space: nowrap;
		overflow: hidden;
		display: flex;
		@include styles.custom_scrollbar;

		@include breakpoints.s {
			height: 100%;
			overflow: auto;
			white-space: normal;
		}
	}
}
