@use "../../styles/vars.scss";

.container {
	z-index: 999;
	max-height: vars.$navbar-height;
	position: fixed;
	top: 0;
	left: 0;
	background: white;
	width: 100%;
	padding: 15px vars.$page-gap;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

	> svg {
		cursor: pointer;
	}
}
