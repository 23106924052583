.zoomed_img_container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.8);

	img {
		padding: 8px;
		background-color: white;
		max-width: 80vw;
		max-height: 80vh;
		height: auto;
		width: auto;
		border: 1px solid black;
		border-radius: 4px;
	}
}
