@use "../../styles/colors.scss";

.linktext {
	text-decoration-line: underline;
	font-weight: bold;
	text-align: center;
	cursor: pointer;
}

.bannertext {
	font-weight: bold;
}

.cookieIcon {
	width: 20px;
	height: 20px;
}
