@use "../../styles/colors.scss";
@use "../../styles/animations.scss";
@use "../../styles/breakpoints.scss";

.deck_container {
	cursor: pointer;
	display: flex;
	flex-direction: column;
	border-radius: 20px;
	border: 1px solid colors.$lightgray;
	width: 100%;
	position: relative;
	height: 132px;
	min-height: 132px;

	@include animations.scale_hover;

	@include breakpoints.s {
		width: 224px;
		height: 262px;
	}

	.deck_name {
		word-break: break-all;
	}

	.deck_header {
		width: 100%;
		height: 32px;
		min-height: 32px;
		border-top-left-radius: 20px;
		border-top-right-radius: 20px;
		align-content: center;

		@include breakpoints.s {
			height: 64px;
			min-height: 64px;
		}
	}

	.deck_body {
		height: 100%;
		padding: 16px 16px;
		border-bottom-left-radius: 20px;
		border-bottom-right-radius: 20px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;

		@include breakpoints.s {
			justify-content: flex-start;

			> :first-child {
				margin-bottom: 16px;
			}
		}
	}

	.indicator {
		position: absolute;
		right: 16px;
		bottom: 16px;

		@include breakpoints.s {
			bottom: 32px;
		}
	}
}
