@use "../../../styles/styles.scss";
@use "../../../styles/colors.scss";

.input {
	@include styles.custom_scrollbar;

	font-family: Berlin Sans FB;
	font-size: 1.4rem;
	font-style: normal;
	font-weight: normal;
	line-height: 24px;
	letter-spacing: 0em;
	word-break: break-word;
	background: none;

	overflow-y: auto;
	overflow-x: hidden;
	border: none;
	text-align: left;

	height: 100%;
	min-height: 100%;
	max-height: 100%;

	padding: 0 16px;

	outline: none;
	resize: none;

	[data-slate-placeholder="true"] {
		margin: 22px 0;
	}
}

.answer {
	color: colors.$darkgray;
}

.question {
	color: black;
}

.toolbar {
	box-shadow: 4px 0 4px -4px rgba(0, 0, 0, 0.25),
		-4px 0 4px -4px rgba(0, 0, 0, 0.25);

	position: absolute;
	top: -38px;
	opacity: 80%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 100%;
	overflow-x: auto;
	overflow-y: hidden;
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
	padding: 8px;
	height: 38px;
	border-bottom: 1px solid white;

	> * {
		border-left: 1px solid white !important;
	}

	> *:last-child {
		border-right: 1px solid white;
	}
}

.toolbar_block {
	font-size: 18px;
	font-weight: 800;
	padding: 0;
	outline: none;
	background-color: inherit;
	border: none;
	border-bottom: none;
	border-top: none;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 24px;
	width: 34px;
	color: rgba(0, 0, 0, 0.5);

	svg > path {
		fill: rgba(0, 0, 0, 0.5);
	}
}

.active {
	color: black;

	svg > path {
		fill: black;
	}
}

.align_container {
	display: flex;
	flex-direction: row;
}

.text_container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	cursor: pointer;
}

.text {
	text-align: center;
	margin-left: 16px;
	margin-right: 16px;
	font-family: Berlin Sans FB;
	font-size: 1.4rem;
}
