@use "./styles/colors.scss";
@use "./styles/breakpoints.scss";
@use "./styles/vars.scss";

@font-face {
	font-family: "Berlin Sans FB";
	src: url("./fonts/BerlinSansFBRegular.ttf") format("truetype");
}

@font-face {
	font-family: "Dubai";
	src: url("./fonts/DubaiRegular.woff") format("woff");
}

html {
	box-sizing: border-box;
	font-family: "Dubai";
	height: -webkit-fill-available;
	background: white;
}
*,
*:before,
*:after {
	box-sizing: inherit;
	-webkit-tap-highlight-color: transparent;
}

body {
	position: fixed;
	inset: 0;
	overflow: hidden;
	margin: 0;

	font-family: Dubai;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

#app-root {
	display: flex;
	flex-direction: column;
	width: 100vw;
	min-height: 100vh;
	min-height: -webkit-fill-available;

	height: -webkit-fill-available;
	overflow-y: auto;
}

.layout {
	flex: 1;
	display: flex;
	flex-direction: column;

	padding: vars.$navbar-height + 8px vars.$page-gap 0 vars.$page-gap;

	@include breakpoints.s {
		padding-top: vars.$navbar-height + 64px;
		margin-left: auto;
		margin-right: auto;
		min-width: breakpoints.$screen-s;
		width: breakpoints.$screen-s;
	}
}

a {
	text-decoration: none;
	color: inherit;
	outline: none;
}

.error {
	font-size: 1.1rem;
	margin: 0;
	text-align: center;
	color: colors.$rederror;
}

.template_text {
	p {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}
}

// set all list color markers to blue
li::marker,
ol::marker {
	color: colors.$darkblue;
}
