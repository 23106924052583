@use "../../styles/colors.scss";

.button {
	width: 100%;
	height: 42px;
	max-width: 343px;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 42px;
	background: colors.$darkblue;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 20px;
	font-size: 18px;
	color: white;
	border: none;
	cursor: pointer;
}

.disabled {
	background-color: colors.$lightgray;
}
