.container {
	flex: 1;

	display: flex;
	flex-direction: column;
	justify-content: center;

	position: relative;

	form {
		> *:not(:last-child) {
			margin-bottom: 45px;
		}
	}

	.password_container {
		> :first-child {
			margin-bottom: 25px;
		}
	}

	img {
		position: absolute;
		right: 0;
		top: 0;
		width: 75px;
		height: 70px;
	}
}

.error {
	position: absolute;
	margin-top: -35px;
	margin-bottom: 0px !important;
	left: 0;
	right: 0;
	text-align: center;
}

.legal_text {
	display: flex;
	flex-wrap: wrap;
	word-wrap: break-word;

	a,
	a:visited {
		color: inherit !important;
	}

	a:focus {
		text-decoration: underline;
	}
}
