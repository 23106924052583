@use "../../styles/vars.scss";

.overlay {
	position: absolute;
	height: 100vh;
	width: 100vw;
	top: 0;
	left: 0;
	z-index: vars.$overlay;
	background: rgba(255, 255, 255, 0.7);
}
