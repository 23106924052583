@mixin scale_hover {
	transform: scale(1);
	transition: 0.2s ease-out;
	-webkit-transform: scale(1);
	-webkit-transition: 0.2s ease-out;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

	&:hover {
		transform: scale(1.03);
		-webkit-transform: scale(1.03);
		box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
	}
}
