.form {
	flex: 1;
	width: 100%;

	display: flex;
	flex-direction: column;
	max-height: 100%;

	position: relative;
}
