@use "../../styles/colors.scss";

.popup_layout {
	background-color: colors.$lightblue;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	display: flex;
	flex-direction: column;
	width: 100vw;
	min-height: 100vh;
	min-height: -webkit-fill-available;
	height: -webkit-fill-available;
	overflow-y: auto;
}

.close_button {
	position: absolute;
	right: 16px;
	top: 16px;
	cursor: pointer;
}
